import { Box } from "@mui/system";
import React from "react";
import Carousel from "react-material-ui-carousel";
import "../../stylesheets/home.css";
import countryHardware from "../images/Country-Hardware-Logo.png";
import picture2 from "../images/houses/DSC_0431.jpg";
import picture3 from "../images/houses/DSC_0440.jpg";
import picture1 from "../images/houses/Poudre.jpg";
import napaLogo from "../images/NAPAKnowHow.JPG";
import blueLightning from "../images/patProjectBlueLightningLogo.png";
import picture4 from "../images/Platt.jpg";
import picture5 from "../images/houses/multigenerational.jpg";
import picture6 from "../images/houses/6274TwilightAve-Exterior.jpg";
import picture7 from "../images/houses/1-Exterior_200227125056.jpg";
import picture8 from "../images/houses/Gunnison_3 Car.jpg";

import stageCoach from "../images/Stagecoach_LOGO_BLK_V2_x95@2x.png";
import trainPicture from "../images/WIGGINS-ELEVATOR-TRAIN.jpg";
import school from "../images/Wiggins-MiddleSchool-HighSchool_Picture.jpg";
import tigers from "../images/Wiggins_Tigers_Logo.png";
import Layout from "../layout/Layout";
import { Typography } from "@mui/material";

export default function Home() {
	return (
		<Layout>
			<a
				href="https://www.iresis.com/cwa/link/listing?llid=tm77qlk5p0t&lid=1289623"
				target="_blank"
				rel="noopener noreferrer"
			>
				<Typography
					sx={{
						position: "sticky",
						zIndex: 5,
						mt: -3,
						mb: -4,
						backgroundColor: "yellow",
					}}
					variant="h6"
					color="red"
				>
					Our house at 106 7th Ave is ready to go. Check it out here!
				</Typography>
			</a>
			<Carousel
				navButtonsAlwaysVisible
				loop={true}
				indicators={false}
				interval="20000"
				swipe={true}
				fullHeightHover={false}
				className="carousel"
			>
				<img className="houseSlides" src={picture1} alt="house1"></img>
				<img className="houseSlides" src={picture2} alt="house2"></img>
				<img className="houseSlides" src={picture3} alt="house3"></img>
				<img className="houseSlides" src={picture4} alt="house4"></img>
				<img className="houseSlides" src={picture5} alt="house5"></img>
				<img className="houseSlides" src={picture6} alt="house6"></img>
				<img className="houseSlides" src={picture7} alt="house7"></img>
				<img className="houseSlides" src={picture8} alt="house8"></img>
			</Carousel>
			<Box
				sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
			>
				<Box
					id="About"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#7d898c",
						border: "0.1rem solid #233243",
					}}
				>
					<Box
						sx={{
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<p className="aboutUs2Valley">
							Welcome! 2 Valley Builders Inc president, John Bailey and Fran
							Burns of Realty One Group Fourpoints have been working together to
							bring beauty and comfort to our wonderful community of Wiggins,
							Colorado. I would like to show you around my community of Wiggins
							by giving you some insight of what we have to offer. I have worked
							in this community and enjoy our small town and the amenities of a
							larger town plus the closeness to the city.
						</p>
						<p className="aboutUs2Valley">
							When looking into moving, you start thinking about all the
							different amenities a new place will be able to provide you and
							your family. Some may think that a small town might not have as
							much to offer, I am here to tell you, think again!
						</p>
						<img className="train" src={trainPicture} alt=""></img>
						<p className="aboutUs2Valley">
							Let me introduce you to some of the highlights of Wiggins,
							Colorado!
						</p>
						<p className="aboutUs2Valley">Patricia Lentell</p>
						<p className="aboutUs2Valley">
							Former Town Clerk, Wiggins Colorado
						</p>
					</Box>
				</Box>
				<Box
					id="LargeAbout"
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#7d898c",
						border: "0.1rem solid #233243",
					}}
				>
					<Box
						id="carouselBox"
						sx={{
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							mt: -4,
							mb: -4,
						}}
					>
						<Carousel
							navButtonsAlwaysVisible
							loop={true}
							indicators={false}
							interval="20000"
							swipe={true}
							fullHeightHover={false}
							className="carousel2"
						>
							<Box className="slides" id="slide1">
								<span className="about">
									<p className="aboutUs">
										Welcome! 2 Valley Builders Inc president, John Bailey and
										Fran Burns of Realty One Group Fourpoints have been working
										together to bring beauty and comfort to our wonderful
										community of Wiggins, Colorado. I would like to show you
										around my community of Wiggins by giving you some insight of
										what we have to offer. I have worked in this community and
										enjoy our small town and the amenities of a larger town plus
										the closeness to the city.
									</p>
									<p className="aboutUs smallerSpace">
										When looking into moving, you start thinking about all the
										different amenities a new place will be able to provide you
										and your family. Some may think that a small town might not
										have as much to offer, I am here to tell you, think again!
										Hop onboard!
									</p>
									<span className="trainImg">
										<img className="train" src={trainPicture} alt=""></img>
									</span>
									<p className="aboutUs smallerSpace">
										Let me introduce you to some of the highlights of Wiggins,
										Colorado!
									</p>
									<p className="aboutUs smallerSpace">Patricia Lentell</p>
									<p className="aboutUs smallerSpace">
										Former Town Clerk, Wiggins Colorado
									</p>
								</span>
							</Box>
							<Box className="slides" id="slide2">
								<span className="blueLogo">
									<a
										href="https://getbluelightning.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img alt="" className="lightning" src={blueLightning} />

										<p className="information">Blue Lightning</p>
										<p className="information">Formerly Wiggins Telephone</p>
										<p className="information">414 Main Street PO Box 690</p>
										<p className="information">Wiggins, CO 80654</p>
										<p className="information">Phone: 970-483-7343</p>
										<p className="information">Fax: 970-483-7713</p>
										<p className="information">Blue Lightning Office Hours:</p>
										<p className="information">8:00 am – 5:00 pm</p>
										<p className="information">Monday – Friday</p>
									</a>
								</span>
								<span className="blueLightning">
									<p className="aboutBlueLightning">
										Blue Lightning, formerly known as Wiggins Telephone, is a
										cooperative that provides local support and has been a part
										of this community since 1951. You can count on high-speed
										internet with 5G connectivity, Skitter TV and telephone
										service. Customers enjoy reliable Internet, speeds that are
										unrivaled by most, and a service that does not get bogged
										down by too many users or impacted by weather-related
										outages. Please read more about this impressive company that
										supports our local and surrounding communities by visiting
										their website at:
									</p>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://getbluelightning.com"
									>
										https://getbluelightning.com.
									</a>
								</span>
							</Box>
							<Box className="slides" id="slide3">
								<span className="blueLogo">
									<a
										href="https://www.countrydoitbesthardware.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img id="countryHardwareImg" alt="" src={countryHardware} />

										<p className="information">218 Main St</p>
										<p className="information">Wiggins, CO 80654</p>
										<p className="information">Phone: 970-483-7312</p>
										<p className="information">Country Hardware Hours:</p>
										<p className="information">8:00 am – 5:00 pm</p>
										<p className="information">Monday – Friday</p>
									</a>
								</span>

								<span className="countryHardware">
									<p className="aboutCountryHardware">
										Welcome to Country Hardware!Country Hardware is a
										family-owned business that has been in Wiggins since 1958.
										Need tools or materials for your next do-it-yourself
										project? Then stop in at Country Do It Best Hardware and be
										treated to that small business personal touch. Special
										orders welcome, they’re not just hardware store! They offer
										window screening, chainsaw sharpening, UPS shipping,
										duplicate keys, hunting & fishing licenses, equipment rental
										along with all the other hardware items you would expect.
									</p>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://www.countrydoitbesthardware.com"
									>
										https://www.countrydoitbesthardware.com
									</a>
								</span>
							</Box>

							<Box className="slides" id="slide4">
								<span className="blueLogo">
									<a
										href="https://www.stagecoachmeatcompany.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img id="stageCoachImg" alt="" src={stageCoach} />

										<p className="information">600 3rd Ave</p>
										<p className="information">
											Wiggins, CO 80654-Morgan County
										</p>
										<p className="information">Phone: 970-483-7312</p>
										<p className="information">
											Stagecoach Meat Company Hours:
										</p>
										<p className="information">8:00 am – 5:00 pm</p>
										<p className="information">Monday – Friday</p>
									</a>
								</span>

								<span className="stageCoach">
									<p className="aboutStageCoach">
										Stagecoach Meat Company is a Butcher and Meat Market in
										Wiggins, Colorado. Their mission is to offer the best
										quality meat at a fair price. The dream was to create a
										custom butcher shop that served the community honestly, and
										consistently. They aim to deliver the best product based on
										care and attention to every detail. In that tradition, as a
										family-owned and operated business, it's their commitment to
										uphold those values. The goal is to deliver "The Best Steak
										You've Ever Had".
									</p>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="https://www.stagecoarchmeatcompany.com"
									>
										https://www.stagecoarchmeatcompany.com
									</a>
								</span>
							</Box>
							<Box className="slides" id="slide5">
								<span className="blueLogo">
									<a
										href="http://www.napaonline.com"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img id="NAPAImg" alt="" src={napaLogo} />
										<p className="information">
											NAPA Auto Parts - Wiggins Farm & Auto Supply
										</p>
										<p className="information">510 Central Ave</p>
										<p className="information">Wiggins, CO 80654</p>
										<p className="information">Phone: 970-483-7357</p>
										<p className="information">NAPA Auto Parts Hours:</p>
										<p className="information">8:00 am – 5:00 pm</p>
										<p className="information">Monday – Friday</p>
									</a>
								</span>
								<span className="NAPA">
									<p className="aboutNAPA">
										Again folks, another locally owned business! NAPA AUTO PARTS
										-Wiggins is your source for quality auto parts for your car
										or truck in Wiggins. Let them use their NAPA Know How to
										help you find the right battery, brakes, filters,
										headlights, wipers or other parts you need to get your job
										done. They also stock tools, equipment and many other items
										for cars, heavy duty trucks, marine, and farm equipment.
										Order your parts online, drop by or call them today. Online
										orders are available for in-store or curbside pickup.
									</p>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href="http://www.napaonline.com"
									>
										http://www.napaonline.com
									</a>
								</span>
							</Box>
							<Box className="slides" id="slide6">
								<span className="schoolLogo">
									<a
										href="http://www.wiggins50.k12.co.us/home"
										rel="noopener noreferrer"
										target="_blank"
									>
										<img id="SchoolImg1" alt="" src={tigers} />
										<img id="SchoolImg2" alt="" src={school} />
										<p className="information">
											District Office - 404 Chapman Street
										</p>
										<p className="information">Preschool - 415 Main Street</p>
										<p className="information">Elementary -415 Main Street</p>
										<p className="information">
											Middle School/High School - 201 Tiger Way
										</p>
										<p className="information">Wiggins, CO 80654</p>
										<p className="information">Phone 970-483-7762</p>
										<p className="information">Fax 970-483-6205</p>
									</a>
								</span>
								<span className="school">
									<p className="aboutSchool bolder">
										POSITIVELY IMPACTING EVERY STUDENT, EVERY DAY
									</p>

									<p className="aboutSchool smallerSpace">
										We are excited for and are proud of our Tigers! Our school
										has a 90% average graduation rate (according to NICHE). Our
										community also supports our sports programs for boys and
										girls; Football, Baseball, Basketball, Cross Country, Track
										& Field, Wrestling and Volleyball. We just recently built
										new and modern facilities. Wiggins School District is a
										public school district that has 715 students in grades PK
										and K-12 with a student-teacher ratio of 16 to 1.
									</p>

									<a
										target="_blank"
										rel="noopener noreferrer"
										href="http://www.wiggins50.k12.co.us/home"
									>
										www.wiggins50.k12.co.us/home
									</a>
								</span>
							</Box>
						</Carousel>
					</Box>
				</Box>
			</Box>
		</Layout>
	);
}
