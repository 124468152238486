import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../../stylesheets/header.css";
import logo from "../images/Header_BG.jpg"
function Header() {
	const [page, setPage] = React.useState("Home");
	const handleChange = (event) => {
		setPage(event.target.value);
	};
	return (
		<Box
			sx={{
				flexGrow: 1,
				display: "flex",
				alignItems: "center",
				mb: 2,
			}}
		>
			<AppBar
				position="static"
				sx={{
					backgroundColor: "#7d898c",
					boxShadow: "none",
					mb: 1,
				}}
			>
				<Toolbar
					sx={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-evenly",
						alignItems: "center",
						borderTop: "0.6rem #233243 solid",
					}}
				>
					<Typography className="largeMenu" variant="header">
						<NavLink to="/" activeClassName="active">
							Home
						</NavLink>
						<NavLink to="../about" activeClassName="active">
							About us
						</NavLink>
						<NavLink to="../multi" activeClassName="active">
							Multi-Generational
						</NavLink>
						<NavLink to="../features" activeClassName="active">
							Features
						</NavLink>
						<NavLink to="../virtual" activeClassName="active">
							Virtual Tours
						</NavLink>
						<NavLink to="../availability" activeClassName="active">
							Availability
						</NavLink>
						<NavLink to="../open" activeClassName="active">
							Open House
						</NavLink>
						<NavLink to="../local" activeClassName="active">
							Local Business
						</NavLink>
					</Typography>
					<Box
						className="smallMenu"
						sx={{
							justifyContent: "center",
							alignItems: "center",
							height: "55px",
						}}
					>
						<FormControl variant="standard">
							<Select
								id="demo-simple-select"
								label="Pages"
								onChange={handleChange}
								value={page}
								sx={{ color: "#e0e0e0" }}
							>
								<MenuItem
									value={"Home"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="/">Home</Link>
								</MenuItem>
								<MenuItem
									value={"About Us"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../about">About Us</Link>
								</MenuItem>
								<MenuItem
									value={"Multi-Generational"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../multi">Multi-Generational</Link>
								</MenuItem>
								<MenuItem
									value={"Features"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../features">Features</Link>
								</MenuItem>
								<MenuItem
									value={"Virtual Tours"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../virtual">Virtual Tours</Link>
								</MenuItem>
								<MenuItem
									value={"Availability"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../availability">Availability</Link>
								</MenuItem>
								<MenuItem
									value={"Open House"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../open">Open House</Link>
								</MenuItem>
								<MenuItem
									value={"Local Businesses"}
									sx={{ display: "flex", mx: 0, justifyContent: "center" }}
								>
									<Link to="../local">Local Businesses</Link>
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Toolbar>
				<Box className="logoBox">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://2valleybuilders-wiggins.com"
					>
						<img
							alt="2 Valley Builders Logo"
							id="logoImg"
							src={logo}
						/>
					</a>
				</Box>
			</AppBar>
		</Box>
	);
}
export default Header;
