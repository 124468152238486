import { Box } from "@mui/system";
import React from "react";
import Layout from "../layout/Layout";
import brendaPic from "../images/brenda.jpg";
import ALM from "../images/ALM.png";
import franPic from "../images/Fran1-2.jpg";
import "../../stylesheets/aboutus.css";
import stMichael from "../images/stmichaelssign.jpg";

export default function AboutUs() {
	return (
		<Layout>
			<Box id="mainBox">
				<Box id="owner-profile">
					<Box className="photo photoLeft">
						<img
							className="profile-image"
							src={franPic}
							alt="Display of Fran Burns"
						/>
					</Box>
					<Box id="biography">
						<Box className="fran-bio">
							<p style={{ textAlign: "center" }}>Fran Burns</p>
							<p style={{ textAlign: "center" }}>
								<a href="tel:+1-970-396-1516">(970) 396-1516 Cell</a>
							</p>
							<p style={{ textAlign: "center" }}>
								<a href="tel:+1-970-396-5880">(970) 573-5880 Office</a>
							</p>
							<p style={{ textAlign: "center" }}>
								<a href="mailto: franburns@comcast.net">
									franburns@comcast.net
								</a>
							</p>
							<br />
						</Box>
						<Box className="bio">
							<p>
								Fran Burns received her real estate license in 1980 and her
								managing broker’s license in 1982. She worked for and was
								trained by Wheeler Realty, located on 8th avenue in Greeley,
								Colorado. Wheeler Realty had offices throughout Northern
								Colorado and Fran became managing broker for the Loveland
								branch. After serving in Loveland, she returned to Greeley and
								was involved in opening new subdivisions and managing. It very
								soon became apparent that her heart was with the public: finding
								just the right real estate fit for each individual’s needs. She
								has been active in the real estate market ever since. Fran's
								husband, Harry, shared her heart for serving the homeless,
								blessing Fairacres with a fantastic Christmas each year, and
								most importantly, coming alongside their adult children and
								grands with love and support. Christ Community is truly her
								extended community, along with her caring and loving neighbors.
								Put some of this experience to work for you by calling Fran at
								970-396-1516.
							</p>
						</Box>
					</Box>
				</Box>

				<Box id="secondary-owner-profile">
					<Box className="photo photoRight">
						<img
							className="secondary-profile-image"
							src={stMichael}
							alt="Display of St. Michael's Church sign"
						/>
					</Box>

					<Box className="john-bio">
						<p style={{ textAlign: "center" }}>2 Valley Builders Inc</p>

						<br />
						<p>
							<b>With us…it’s personal</b>
						</p>
						<br />
					</Box>
					<Box className="bio">
						<p>
							John Bailey is the President and owner of 2 Valley Builders, Inc.
							formerly known as Bailey Homes, Inc. He has been in business since
							1996. 2 Valley Builders Inc. is a small locally owned business
							uniquely suited for building homes in Eastern and Northern
							Colorado. They have built over 350 homes in Ault, Evans, Greeley,
							Johnstown, Milliken, Firestone, Loveland, Fort Collins and
							Wiggins. They personally design and build homes, paying meticulous
							attention to detail, space and functionality. It has always been
							our goal to build quality, affordable homes that stand the test of
							time, in good neighborhoods and communities that will give your
							family the opportunity to live, play and relax. Please join us in
							Willowbrook subdivision, Evans; Kiowa Park subdivision, Wiggins;
							and Severance Shores, Severance. We know you will enjoy all of the
							convenience and peacefulness these communities have to offer. We
							are also building new multi-family homes in various subdivisions.
						</p>
					</Box>
				</Box>
			</Box>
			<Box className="brenda">
				<Box className="brendaProfile">
					<img
						className="brenda-image"
						src={brendaPic}
						alt="Brenda face profile"
					/>
					<p>NMLS# 775984</p>
				</Box>
				<Box className="info">
					<p>Brenda Frank</p>
					<p>Sr Loan Originator</p>
					<Box className="info2">
						<p>P: 970-966-8696</p>
						<p>M: 970-690-6461</p>
						<p>F: 888-908-7177</p>
						<p>E: brenda.frank@almortgageinc.com</p>
						<p>almnoco.com</p>
					</Box>
					<Box className="apply-button">
						<a href="https://brendafrank.floify.com" className="brendaApply">
							APPLY NOW | log-in
						</a>
					</Box>
				</Box>
				<Box className="almLogo">
					<img className="alm" src={ALM} alt="ALM logo" />
				</Box>
			</Box>
		</Layout>
	);
}
