import { Box } from "@mui/system";
import React from "react";
import Layout from "../layout/Layout";
import mainPic from "../images/houses/multigenerational.jpg";
import upperPic from "../images/RIO-GRANDE_MainFloor-Layout.jpg";
import lowerPic from "../images/RIO-GRANDE_LowerLevel-Layout.jpg";
import { Typography } from "@mui/material";

export default function MultiGenerational() {
	return (
		<Layout>
			<Box
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<Typography sx={{ mb: 3, width: "50%" }}>
					Multigenerational Home with a flexible floor plan that will maximize
					your home buying budget!
					<br />
					2 bedrooms to 5 bedrooms depending on your family requirements
					<br />
					Private living areas and warm open gathering spaces designed for your
					individual needs.
					<br />
					Builder consultations scheduled for your specific requests.
				</Typography>
			</Box>
			<Box class="mainImage">
				<img
					style={{ width: "50%", marginTop: "20px" }}
					class="rioImage"
					src={mainPic}
					alt="Display of the Rio house"
				/>
			</Box>
			<Box class="layoutImage">
				<Box class="mainLevel">
					<img
						style={{ width: "50%", marginTop: "20px" }}
						class="mainFloor"
						src={upperPic}
						alt="Display of the main floor layout"
					/>
				</Box>
				<Box class="lowerLevel">
					<img
						style={{ width: "50%", marginTop: "20px" }}
						class="lowerImage"
						src={lowerPic}
						alt="Display of the lower floor layout"
					/>
				</Box>
			</Box>
		</Layout>
	);
}
