import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import AboutUs from "./components/pages/AboutUs";
import Availability from "./components/pages/Availability";
import Features from "./components/pages/Features";
import Home from "./components/pages/Home";
import LocalBusiness from "./components/pages/LocalBusiness";
import MultiGenerational from "./components/pages/MultiGenerational";
import OpenHouse from "./components/pages/OpenHouse";
import VirtualTours from "./components/pages/VirtualTours";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route index element={<Home />} />
					<Route path="about" element={<AboutUs />} />
					<Route path="features" element={<Features />} />
					<Route path="virtual" element={<VirtualTours />} />
					<Route path="availability" element={<Availability />} />
					<Route path="local" element={<LocalBusiness />} />
					<Route path="multi" element={<MultiGenerational />} />
					<Route path="open" element={<OpenHouse />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
