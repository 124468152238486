import { Box } from "@mui/system";
import React from "react";
import Layout from "../layout/Layout";
// import flyer from "../images/09.2022_OpenHouse-4thofJuly-Flyer.jpg";

export default function OpenHouse() {
  return (
    <Layout>
		<Box class="flyer">
			  {/* <img src={flyer} alt="Open house flyer"></img> */}
			  <Box height={780}><h1>Check back later for upcoming Open Houses!</h1></Box>
		</Box></Layout>
	);
}