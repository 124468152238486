import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import floorplan from "../images/emmamainfloor.jpg";
import sonoma from "../images/houses/6274TwilightAve-Exterior.jpg"

function SimpleDialog(props) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					p: 1,
					m: 1,
				}}
			>
				<DialogTitle>The Sonoma</DialogTitle>
				<img
					src={floorplan}
					alt="Floor plan for the Sonoma home"
					width={"95%"}
				/>
			</Box>
		</Dialog>
	);
}

export default function Sonoma() {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};
	return (
		<div>
			<Button
				sx={{ "&:hover": { border: "1px solid yellow"} }}
				variant="outlined"
				onClick={handleClickOpen}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					  }}
				>
					<Typography variant="body" color="white">
						The Sonoma
					</Typography>
					<img
						className="feature"
						width={150}
						height={150}
						src={sonoma}
						alt=""
					/>
				</Box>
			</Button>
			<SimpleDialog open={open} onClose={handleClose} />
		</div>
	);
}
