import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Bath from "../Modals/Bath";
import Energy from "../Modals/Energy";
import Gunnison from "../Modals/Gunnison";
import Layout from "../layout/Layout";
import Platt from "../Modals/Platt";
import Poudre from "../Modals/Poudre";
import Standard from "../Modals/Standard";
import SanLuis from "../Modals/SanLuis";
import Shenandoah from "../Modals/Shenandoah";
import GrandValley from "../Modals/GrandValley";
import Sonoma from "../Modals/Sonoma";

export default function Features() {
		return (
		<Layout>
			<Box sx={{display: "flex", flexDirection: "column", flexWrap: "wrap"}}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						minHeight: "380px",
					}}
				>
					<Standard />
					<Energy />
					<Bath />
				</Box>
				<Typography sx={{ mt: 4 }} variant="h5" color="white">
					Floor plans
				</Typography>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexWrap: "wrap",
						minHeight: "330px",
					}}
				>
					<Poudre />
					<Platt />
					<Gunnison />
					<SanLuis />
					<Shenandoah />
					<GrandValley />
					<Sonoma />
				</Box>
			</Box>
		</Layout>
	);
}
