import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
// import Popper from "@mui/material/Popper";
import React from "react";
import "../../stylesheets/footer.css";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import FacebookIcon from "@mui/icons-material/Facebook";
import Tooltip from "@mui/material/Tooltip";

function Footer() {
	return (
		<Box className="mainBox">
			<Typography variant="caption" className="developers">
				Developers: Brion & Stephanie Lentell
			</Typography>

			<Box className="contactBox">
				<a className="email" href="mailto: franburns@comcast.net">
					<Tooltip
						placement="top"
						title="Email Fran: franburns@comcast.net"
					>
						<ContactMailIcon></ContactMailIcon>
					</Tooltip>
				</a>
				<a className="phone" href="tel: 970-396-1516">
					<Tooltip placement="top" title="Call Fran: 970-396-1516">
						<ContactPhoneIcon></ContactPhoneIcon>
					</Tooltip>
				</a>
				<a
					className="facebook"
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.facebook.com/homesinnortherncolorado"
					alt="Homes in Northern Colorado Facebook Page"
				>
					<Tooltip
						placement="top"
						title="Homes in Northern Colorado Facebook Page"
					>
						<FacebookIcon></FacebookIcon></Tooltip>
					</a>
				</Box>
			</Box>
	);
}
export default Footer;
