import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import standards from "../../shared/features.json";
import { Box } from "@mui/system";
import {IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import feature1 from "../images/features_pic1.jpg";
function SimpleDialog(props) {
	const { onClose, open } = props;

	const handleClose = () => {
		onClose();
	};

	return (
		<Dialog onClose={handleClose} open={open}>
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					p: 1,
					m: 1,
				}}
			>
				<DialogTitle>Standard Construction Features</DialogTitle>
				{standards.map((standard) => (
					<Typography>{standard.construction}</Typography>
				))}
			</Box>
		</Dialog>
	);
}

export default function Standard() {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};
	return (
		<div>
			<Button
				sx={{ "&:hover": { border: "1px solid yellow" } }}
				variant="outlined"
				onClick={handleClickOpen}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="body" color="white">
						Standard Construction Features
					</Typography>
					<img
						className="feature"
						width={300}
						height={300}
						src={feature1}
						alt="Standard Construction Features"
					/>
				</Box>
			</Button>
			<SimpleDialog open={open} onClose={handleClose} />
		</div>
	);
}