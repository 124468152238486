import { Box } from "@mui/system";
import React from "react";
import Layout from "../layout/Layout";
import "../../stylesheets/availability.css";
export default function Availability() {
	return (
		<Layout>
			<Box class="listings">
				<embed
					src="https://www.iresis.com/cwa/link/listings?llid=tm77qlk5p0t"
					width="100%"
					height="700"
				></embed>
			</Box>
		</Layout>
	);
}
