import React from "react";
import Layout from "../layout/Layout";
import Box from "@mui/material/Box";
import BusinessCard from "../layout/BusinessCard";


export default function LocalBusiness() {
	return (
		<Layout>
			<Box
				style={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<BusinessCard />
			</Box>
		</Layout>
	);
}
