import { Box } from "@mui/system";
import React from "react";
import Layout from "../layout/Layout";

export default function VirtualTours() {
	return (
		<Layout>
			<Box id="container">
				<h1 class="vt">The Platte II</h1>
				<Box sx={{ height: "480px" }}>
					<iframe
						title="The Platte 2"
						src="https://www.zillow.com/view-3d-home/373efaf3-a00e-4da3-99ab-586d48bbb7e4?setAttribution=mls&wl=true"
						frameborder="0"
						width={"90%"}
						height={"100%"}
						allowfullscreen
						allow="xr-spatial-tracking"
					></iframe>
				</Box>
				<h1 class="vt">Shenandoah</h1>
				<Box sx={{ height: "480px" }}>				
				<iframe
					title="Shenandoah"
					src="https://www.zillow.com/view-3d-home/a4a47627-9a98-4109-859c-bdb0e236ae63?setAttribution=mls&wl=true"
					frameborder="0"
					width={"90%"}
					height={"100%"}
					allowfullscreen
					allow="xr-spatial-tracking"
					></iframe>
					</Box>

				<h1 class="vt">3-car Poudre</h1>
				<Box sx={{ height: "480px", mb: "1em" }}>
				<iframe
					title="3 car Poudre"
					src="https://www.zillow.com/view-3d-home/00790972-25ad-41e3-8527-d8114a6833cf?setAttribution=mls&wl=true"
					frameborder="0"
					width={"90%"}
					height={"100%"}
					allowfullscreen
					allow="xr-spatial-tracking"
					></iframe>
					</Box>
			</Box>
		</Layout>
	);
}
