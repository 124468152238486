import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import business from "../../shared/localBusinesses.json";
import "../../stylesheets/businessCard.css";

export default function BusinessCard() {
  return (
		<React.Fragment>
			{business.map((item) => (
				<a target="_blank" rel="noopener noreferrer" href={item.website}>
					<Card
						sx={{
							border: "3px solid #7D898C",
							borderRadius: "30px",
							height: 300,
							width: 450,
							margin: "20px",
							backgroundColor: "#EBDFCF",
						}}
					>
						<CardContent>
							<Typography
								sx={{ textDecoration: "underline" }}
								variant="h6"
								color="text.primary"
								gutterBottom
							>
								{item.businessName}
							</Typography>
							<Typography variant="body" component="p">
								{item.businessAddress}
							</Typography>
							<Typography sx={{ mb: 1 }} variant="body" component="p">
								{item.cityStateZip}
							</Typography>
							<Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
								phone: {item.phone}
							</Typography>
							<Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
								email: {item.email}
							</Typography>
							<Typography sx={{ mb: 1 }} variant="body2" color="text.secondary">
								website: {item.website}
							</Typography>
						</CardContent>
					</Card>
				</a>
			))}
		</React.Fragment>
	);
}
